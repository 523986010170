.topnav_background{
    position: fixed;

    display: flex;

    width: 100vw;
    height: 7.223942208462332vh;

    overflow: hidden;

    background-color: black;

    z-index: 1000;
  }

  .topnav{
    display: flex;

    width: 100%;
    height: 100%;

    align-items:center;
    justify-content: space-between;

    margin: 0 auto 0 auto;
  }
  
  .topnav p {
    display: flex;
    color:white;
    font-family: "JS Dong Kang KR";
    font-weight: normal;
    
    text-decoration: none;
    font-size: 0.8854166666666666vw;
  }
  
  .topnav a.leftAtt{
    margin-left: 10px;
  }

  .topnav div.Button{
    display: flex;

    width: 10.416666666666666vw;
    height: 6.191950464396285vh;

    cursor: pointer;

    align-items: center;
    justify-content: center;

    transform: translate(-20%);
  }

  .topnav div.Button img{
    width: 10.416666666666666vw;
    height: 6.191950464396285vh;
  }

  .topnav_icon{
    display: flex;
    
    align-items: center;

    list-style: none;

    margin-right: 30px;
  }

  .topnav_icon img{
    width: 2.0833333333333335vw;
    height: 4.12796697626419vh;

    margin: 3px;
  }

  .topnav_icon img.Youtube{
    width: 2.6041666666666665vw;
    height: 4.12796697626419vh;
  }

  .topnav_icon_small{
    display: none;

    width: 40px;
    height: 36px;
  }
  
  .topnav a.active {
    background-color: #4CAF50;
    color: white;
  }

  .Title{
     width: 15.104166666666666vw;
     height: auto;

     margin-top: 5px;
  }

  .Logo{
    display: none;
 }

  
  .Instagram {
    background-image: url(https://www.instagram.com/kah.cg/);
  }
  .on {
    display: none;
  }

  .layer_popup {
    display: none;
  }

  @media screen and (max-width: 1920px)
  {
    .topnav_background{
      position: fixed;
  
      display: flex;
  
      width: 100vw;
      height: 70px;
  
      overflow: hidden;
  
      background-color: black;
  
      z-index: 1000;
    }

    .topnav p {
      display: flex;
      color:white;
      font-family: "JS Dong Kang KR";
      font-weight: normal;
      
      text-decoration: none;
      font-size: 17px;
    }

    .topnav div.Button{
      display: flex;
  
      width: 200px;
      height: 60px;
  
      cursor: pointer;
  
      align-items: center;
      justify-content: center;
  
      transform: translate(-20%);
    }
  
    .topnav div.Button img{
      width: 200px;
      height: 60px;
    }

    .topnav_icon img{
      width: 40px;
      height: 40px;
  
      margin: 3px;
    }

    .topnav_icon img.Youtube{
      width: 50px;
      height: 40px;
    }

    .Title{
      width: auto;
      height: auto;
 
      margin-top: 5px;
   }
  }


  @media screen and (max-width: 1200px)
  {
    .topnav{
      display: flex;
      max-width: 1200px;
  
      width: 100%;
      height: 100%;
  
      align-items:center;
  
      margin: 0 auto 0 auto;
    }
  }

  @media screen and (max-width: 750px) {

    .topnav{
      display: flex;
      max-width: 1200px;
  
      width: 100%;
      height: 100%;
  
      align-items:center;
  
      margin: 0 auto 0 auto;
    }

    .topnav div.Button{
      display: none;
    }

    .topnav_icon {
      display: none;
    }
    .topnav_icon_small{
      display: flex;
  
      margin-left: auto;
      align-items: center;
  
      list-style: none;
  
      margin-right: 20px;
    }

    .on {
      display: block;
    }

    .layer_popup{ 
      position: fixed; 
      top: 0; 
      left: 0; 
      right: 0; 
      bottom: 0; 
    } 

    .layer{
      color: white;
      width: 100%; 
      height: 100vh;
      position: relative; 
      top: 0; 
      left: 0; 
      bottom: 0; 
      right: 0; 
      margin: auto; 
      border-radius: 5px; 

      z-index: 100;
    } 

    .menu_bar{ 
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-top: 15%;
    }

    .menu_item{
      list-style: none;
      padding-left:0px;
    }

    .menu_item li{
      width: 100%;
      text-align: center;
      padding: 0;
    }

    .menu_item p{
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .menu_item img{
      width: 40px;
      height: 40px;

      padding: 10px;
    }

    .link{ 
      color: white;
      font-family: "JS Dong Kang KR";
      font-weight: normal;
      font-size: 20px; 
      line-height: 1.5; 
      text-align: justify;
    }  

    .link:hover{ 
      background: rgba(148, 25, 25, 0.932);
    }  

    .Title{
      display: none;
   }

   .Logo{
      width: 45px;
      height: 60px;

      margin-left: 10px;

      display: inline;
    }

    .btn_area{ 
      padding: 30px; 
      display: table; 
      table-layout: fixed; 
      width: 100%; 
      box-sizing: border-box; 
    }
 
    .btn_close{
      color: white; 
      border: none; 
      font-family: "JS Dong Kang KR";
      font-weight: bold;
      font-size: 15px;
      width: 80px;
      height: 70px;
      background: transparent;
      position: absolute;
      top:0%;
      right: 0px; 
      cursor: pointer; 
       
      }
      .dimmed{ 
        background-color: rgba(0, 0, 0, 0.7); 
        position: absolute; 
        left: 0; 
        right: 0; 
        top: 0; 
        bottom: 0; 
        z-index: -1; 
      }
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
      }
      .maker {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin-top: 50px;
        font-size: 25px;
      }
  }