.PollContent{
    position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
    text-align: center;
    
    width: max-content;
    height:90%;
}

.PollContent div{
    position: relative;
    display: grid;
    
    height: inherit;

    justify-items: center;
}

.PollContent a{
    position: relative;

    font-family: "JS Dong Kang KR";
    font-weight: normal;
    font-size: 0.78125vw;

    padding-bottom: 10px;
}
.PollContent img{
    position: relative;

    width: 15.625vw;
    height: 41.2796697626419vh;
}

.PollContent button{
    position: relative;

    width: 6.40625vw;
    height: 5.159958720330238vh;

    background: url(https://i.ibb.co/6bKTrKx/Vote-Button.png) no-repeat ;
    background-size: cover;

    margin-top: 10px;
}

.voteSelect{
    width: 100%;
    height: 100%;
}

.carousel-container{
    
    width: 100%;
    height: 100%;

    top:8%;
}

.carousel-item{
    display: flex;
    justify-content: center;

    width: min-content;
    height: min-content;
}

.VoteResult:not(.PollContainer){
    position: absolute;

    width: 90%;
    height: 55%;

    top:25%;
}

.Info:not(.PollContainer){
    position: absolute;
    
    display: grid;
    grid-template-areas: 
    'AwardName Button'
    'AwardInfo AwardInfo';

    align-items: center;
    justify-content: center;

    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2fr 1.5fr ;

    left: 4%;

    top:12%;

    z-index: 1;
}

.Info a{
    grid-area: AwardName;

    font-family: "JS Dong Kang KR";
    font-weight: bold;
    font-size: 2.6041666666666665vw;
}

.Info a.AwardInfo{
    grid-area: AwardInfo;

    position: absolute;

    font-family: "JS Dong Kang KR";
    font-weight: bold;
    font-size: 1.0416666666666667vw;

    bottom: 0%;
}

.Info button{
    grid-area: Button;

    margin-left: 2vw;

    width: 5.31632110579479vw;
    height: 3.6496350364963503vh;

    background: url(https://i.ibb.co/7bhVrq8/Result-Check.png) no-repeat;
    background-size: cover;
}

.TopLine:not(.PollContainer){
    position: absolute;

    width: 100%;
    height: 10px;

    top: 2%;
}

.BottomLine:not(.PollContainer){
    position: absolute;

    width: 100%;
    height: 10px;

    bottom: -3%;
}

@media screen and (max-width: 1920px)
{
    .PollContent a{
        font-size: 15px;
    
        padding-bottom: 10px;
    }

    .PollContent img{
        width: 300px;
        height: 400px;
    }  

    .PollContent button{
        width: 123px;
        height: 50px;

        margin-top: 10px;
    }
    
    .Info a{
        font-size: 50px;
    }

    .Info a.AwardInfo{
        font-size: 20px;
    }
    
    .Info button{
        margin-left: 10px;
    
        width: 100px;
        height: 35px;
    }
}

@media screen and (max-width: 908px){

    .Info:not(.PollContainer){
        width: 100%;
        height: 100px;

        justify-content: left;
        align-items: baseline;

        display: flex;
    }
}

@media screen and (max-width: 500px){

    .Info:not(.PollContainer){
        width: 100%;
        height: 10%;

        justify-content: left;
        align-items: baseline;

        display: flex;

        top: 9%;
    }

    .Info a{
        position: relative;

        font-family: "JS Dong Kang KR";
        font-weight: bold;
        font-size: 3.1vh;
    }

    .Info a.AwardInfo{
        grid-area: AwardInfo;
    
        position: absolute;
    
        font-family: "JS Dong Kang KR";
        font-weight: bold;
        font-size: 2vh;
    }

    .Info button{
        width: 15.4vw;
        height: 2.73vh;
    }

    .PollContent a{
        font-size: 3vw;
    
        padding-bottom: 1vh;
    }

    .PollContent img{
        width: 60vw;
        height: 42vh;
    }  
    
    .PollContent button{
        width: 25vw;
        height: 5.6vh;

    }
}

