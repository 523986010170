.Poll{
    height: min-content;
}

.PollContainer{
    position: relative;
    display: flex;

    min-height: 400px;

    width: 67.7083vw;
    height: 92.87925696594428vh;

    left: 50%;

    justify-content: center;
    align-items: center;
    
    transform: translate(-50%, 0%);
}

.PollContainer a{
    color: white;
}

@media screen and (max-width: 1920px)
{
    .PollContainer{
        width: 1300px;
        height: 900px;
    }
}


@media screen and (max-width: 1200px)
{
    .PollContainer{
        position: relative;
        display: flex;

        min-height: 400px;
    
        width: 100vw;
        height: 900px;
    
        justify-content: center;
        align-items: center;
        
        transform: translate(-50%, 0%);
    }
}

@media screen and (max-width: 500px)
{
    .PollContainer{
        height: 78.8vh;
    }
}

