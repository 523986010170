.SliderContainer{
    position: absolute;

    width: 63vw;
    height: 60vh;
    
    transform: translate(-50%, -50%);

    display: flex;

    align-items: center;

    top: 50%;
    left: 50%;
}

.SliderCard{
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url(image/GameExhibit.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    pointer-events: auto;

    display: flex;

    align-items: center;
    justify-content: center;
}

.grid-container{
    width: 90%;
    height: 97%;

    display: grid;
    grid-template-areas: 
    'GamePoster GameName'
    'GamePoster main'
    'GamePoster main'
    'GamePoster main'
    'GamePoster Platform';

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

}

.SliderCard a{
    z-index: inherit;
}
.GamePoster{
    grid-area: GamePoster;
    text-align: center;
}

.GameName{
    display: flex;
    grid-area: GameName;
    align-items: center;
}

.Content{
    display: flex;

    grid-area: main;

    align-items: center;
}

.Platform{
    grid-area: Platform;
}   

.GamePoster img{
    position: absolute;

    width: 25vw;
    height: 36vh;

    top:50%;

    transform: translate(-50%, -50%);
}

.SliderCard p.gameName{
    position: relative;
    pointer-events: none;

    font-family:"JS Dong Kang KR";
    font-weight: bold;
    font-size: 2vw;
    color: white;
    left: 50%;

    transform: translate(-50%);
}

.SliderCard p.context{
    position: relative;

    pointer-events: none; 

    font-family:"JS Dong Kang KR";
    font-weight: normal;
    font-size: 1vw;
    color: white;

    left: 1%;
}

.SliderCard a.icon{
    position: absolute;

    width: 100px;
    height: 100px;

    left: 80%;
    top: 70%;
}

.Platform a{
    position: absolute;

    width: min-content;
    height: min-content;

    right: 5%;

    cursor: pointer;
}

.Platform img{
    width: 3vw;
    height: 7vh;
}

@media screen and (max-width: 1920px){

    .SliderContainer{
        position: absolute;

        width: 1200px;
        height: 580px;

    }

    .GamePoster img{
        width: 450px;
        height: 350px;
    }

    .SliderCard p.gameName{
        font-size: 40px;
    }

    .SliderCard p.context{
        font-size: 19px;

    }

    .Platform img{
        width: auto;
        height: 60px;
    }
}

@media screen and (max-width: 1200px){
    .SliderContainer{
        position: absolute;

        width: 850px;
        height: 660px;
        
        transform: translate(-50%, -50%);
    
        top: 50%;
        left: 50%;
    }
    .SliderCard{

        width: 100%;
        height: 90%;

        background-image: url(image/Tablet_Game_Exhibit.svg);

        align-items: center;
        justify-content: center;
    }

    .grid-container{
        width: 90%;
        height: 85%;
    
        grid-template-rows: 1fr 0.5fr 1.5fr 1fr 1fr;

        margin-top: 25px;
    }

    .GamePoster img{
        position: absolute;
    
        width: 350px;
        height: 250px;
    
        transform: translate(-50%, -45%);
    }

    .SliderCard p.gameName{
        font-size: 35px;

        transform: translate(-50%);
    }
}

@media screen and (max-width: 850px){
    .SliderContainer{
        width: 800px;
    }

    .grid-container{
        grid-template-rows: 1fr 0.3fr 1.7fr 1fr 1fr;
    }
}

@media screen and (max-width: 800px){
    .SliderContainer{
        width: 500px;
    }

    .SliderCard{
        background-image: url(image/Moblie_Game_Exhibit.svg);
    }

    .grid-container{
        width: 80%;
        height: 90%;

        grid-template-areas: 
        'GamePoster GamePoster'
        'GamePoster GamePoster'
        'GamePoster GamePoster'
        'GameName GameName'
        'GameName GameName'
        'main main'
        'Platform Platform';

        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.5fr 1.5fr 1.5fr 0.6fr 0.6fr 1.5fr 1.5fr;
    
        margin-top: 30px;
    }

    .GamePoster{
        grid-area: GamePoster;
        text-align: justify;
    }

    .GamePoster img{
        position: relative;
    
        width: 350px;
        height: 240px;

        top: 50%;
        left: 50%;
    
        transform: translate(-50%, -50%);
    }

    .GameName{
        grid-area: GameName;
        text-align: center;
    }

    .SliderCard p.gameName{
        position: relative;

        font-size: 30px;
    }

    .SliderCard p.context{
        font-size: 17px;
    }

    .Content{
        align-items: baseline;
    }

    .Platform{
        display: flex;

        align-items: center;
    }   

    .Platform a{
        position: relative;
    
        width: min-content;
        height: min-content;

        left: 85%;
    }

    .Platform img{
        position: relative;

        width: 50px;
        height: 60px;
    }
    
}

@media screen and (max-width: 500px){
    .SliderCard{
        background-image: url(image/Moblie_Game_Exhibit.svg);
    }

    .SliderContainer{
        position: absolute;

        width: 100vw;
        height: 70vh;
        
        transform: translate(-50%, -50%);
    
        top: 50%;
        left: 50%;
    }

    .grid-container{
        width: 42vh;
        height: 90%;
    }

    .GamePoster img{
        position: relative;
    
        width: auto;
        height: 25vh;
    
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    .SliderCard p.gameName{
        font-size: 4vh;
    }

    .SliderCard p.context{
        font-size: 1.8vh;
    }

    .Platform img{
        position: relative;

        width: auto;
        height: 6.4vh;
    }
}


@media screen and (max-width: 412px)
{
    .SliderCard p.context{
        font-size: 15px;
    }

    .grid-container{
        margin-right: 10px;
    }
}

@media screen and (max-width: 375px)
{

    .SliderCard p.context{
        font-size: 12px;

    }

    .Platform img{
        position: relative;

        width: 35px;
        height: 40px;
    }

}

@media screen and (max-width: 312px)
{
    .SliderCard p.context{
        font-size: 10px;

    }

    .Platform img{
        position: relative;

        width: 25px;
        height: 30px;
    }

}

