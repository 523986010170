.background
{
    background-color: black;

    width: 100vw;
    height: 100vh;
}

.main{
    position: relative;

    display: flex;

    width: min-content;
    height: max-content;

    align-items: center;
    justify-content: center;

    background-color: black;

    transform: translate(0%, 7.120743034055727vh);

    z-index: 2;
}

.main div.GameInfo{
    position: relative;

    width: 100vw;
    height: 92vh;

    overflow: hidden;

    z-index: 1;
}

.main div.Vote{
    position: relative;

    width: 100vw;
    height: inherit;

    display: none;
    overflow: hidden;

    justify-content: center;

    z-index: 1;
}

.LeftDeco{

    position: absolute;

    width: 15.657620041753653vw;
    height: 67.42738589211618vh;

    top: 57%;
    left: 5%;
    transform: translate(0%, -50%);
}

.RightDeco{

    position: absolute;

    width: 15.657620041753653vw;
    height: 67.42738589211618vh;

    top: 57%;
    right: 5%;

    transform: translate(0%, -50%);
}

.RightButton{
    position: absolute;

    width: 20px;
    height: 20px;

    top: 50%;

    transform: translate(50%, 50%);

}

.LeftButton{
    position: absolute;

    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1920px)
{
    .main{
        transform: translate(0%, 69px);
    }

    .main div.GameInfo{
    width: 100vw;
    }

    .main div.Vote{
        width: 100vw;
    }
    
    .LeftDeco{
        width: 300px;
        height: 650px;
    }
        
    .RightDeco{
        width: 300px;
        height: 650px;
    }
}

@media screen and (max-width: 1800px){
    .LeftDeco{
        display: none;
    }
    
    .RightDeco{
        display: none;
    }
}



@media screen and (max-width: 1200px)
{
    .main{
        width: auto;
        height: max-content;
    }

    
    .main div.GameInfo{
        position: relative;
        
        width: 100vw;
    }

    .main div.Vote{
        position: relative;
    
        width: inherit;
        height: inherit;
    
        display: none;
    
        justify-content: center;
    
        z-index: 1;
    }
}
