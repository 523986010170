body {
  position: fixed;

  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  overscroll-behavior: contain;

  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.root{
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: "JS Dong Kang KR";
  font-style: normal;
  font-weight: normal;
  src:local('JSDongkang-Regular'),
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Regular.ttf") format("woff2"), 
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Regular.ttf") format("woff"),
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "JS Dong Kang KR";
  font-style: normal;
  font-weight: bold;
  src:local('JSDongkang-Bold'),
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Bold.ttf") format("woff2"), 
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Bold.ttf") format("woff"),
      url("https://cdn.jsdelivr.net/gh/yungjin702/gamejam2021@master/JSDongkang-Bold.ttf") format("truetype");
}
