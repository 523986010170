.DotBackground{
    width: 100vw;
    height: 90vh;
}

.DotIndicator{
    position: relative;

    width: 63vw;
    height: 100%;

    left: 50%;


    transform: translate(-50%);
}

@media screen and (max-width: 1920px){
    .DotIndicator{
        width: 1200px;
        height: 100%;
    }
}
