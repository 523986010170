.DotNavContainer:not(.DotIndicator):not(.DotBackground){
    position: absolute;

    text-align: center;

    top: 50%;
    right: -5%;

    transform: translate(-50%, -50%);

    z-index: 1;
}

.Dot{
    list-style: none;
    width: 1.5560165975103735vh;
    height: 0.7828810020876826vw;
    background-color: rgba(187, 42, 42, 0.363);
    border-radius: 50%;
    cursor: pointer;

    margin-top: 3px;
}

.Dot_Active{
    width: 1.5560165975103735vh;
    height: 0.7828810020876826vw;
    border-radius: 50%;
    background-color: rgb(255, 0, 0);

    margin-top: 3px;
}

.Dot:hover{
    background-color: rgba(0, 0, 0, .1);
}

@media screen and (max-width: 1920px)
{   
    .DotNavContainer{
        top: 50%;
        right: -5%;
    }

    .Dot{
        width: 15px;
        height: 15px;
    }
    
    .Dot_Active{
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width: 1350px)
{
    .DotNavContainer{
        display: none;
    }
}